import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history'
import 'antd/dist/antd.css';
// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { RecoilRoot } from 'recoil';

export const history = createBrowserHistory()

ReactDOM.render(
  <RecoilRoot>
    <Router history={history}>
      <ScrollToTop />
      <HomePage />
    </Router>
  </RecoilRoot>,
  document.getElementById("root")
);
