
export const Routes = {
    // pages
    Signin: "/sign-in",
    ForgotPassword: "/forgot-password",
    ResetPassword: "/reset-password",
    Lock: "/lock",
    NotFound: "/404",
    DashboardOverview: "/dashboard",
    Transactions: "/transactions",
    Admin: "/admin",
    Settings: "/settings",
    About: "/about",
    Blogs: "/blogs",
    Merchants: "/merchants",
    Agents: "/agents",
    Contact: "/contact",
    Home: "/home",
    HowItWork: "/howItWork",
    Product: "/product",
    WayaPos: "/waya-pos",
    Subscribers: "/subscribers",
    Messages: "/messages",
    Features: "/features",
    // components
    Accordions: "/components/accordions",
    Alerts: "/components/alerts",
    Badges: "/components/badges",
    Widgets: "/widgets",
    Breadcrumbs: "/components/breadcrumbs",
    Buttons: "/components/buttons",
    Forms: "/components/forms",
    Modals: "/components/modals",
    Navs: "/components/navs",
    Navbars: "/components/navbars",
    Pagination: "/components/pagination",
    Popovers: "/components/popovers",
    Progress: "/components/progress",
    Tables: "/components/tables",
    Tabs: "/components/tabs",
    Tooltips: "/components/tooltips",
    Toasts: "/components/toasts",
    WidgetsComponent: "/components/widgets"
};