import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Row, Col } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faHome } from '@fortawesome/free-solid-svg-icons';
import { Form, Input, Modal, Button as AntButton, Table, Space, Tag, Upload, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { createFeature, updateFeature } from '../../../services/apiCalls';
import AlertToast from '../../../components/AlertToast';
import { featureAlert, featureResponse, features, homePage, homeRefresh } from '../../../states/home';
import { dummyRequest } from '../../../services/utilities';
import { imageUrl } from '../../../services/axios';
import HomeCard from './HomeCard';
import MediaCard from './MediaCard';

const Home = () => {
  // const data = useRecoilValueLoadable(features)
  const data = useRecoilValue(homePage)
  const [viewModal, setViewModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState({})
  const [file, setFile] = useState("")
  const [showAlert, setShowAlert] = useRecoilState(featureAlert);
  const [response, setResponse] = useRecoilState(featureResponse)
  const [uploadImage, setUploadImage] = useState(false)
  const setRefresh = useSetRecoilState(homeRefresh)
  const [form] = Form.useForm()
  const { confirm } = Modal;
  const home = data?.data
  const featureList = data?.homeFeatures
  const explore = featureList.filter(f => f.type == "explore")
  const feature = featureList.filter(f => f.type == "home")
  const unique = featureList.filter(f => f.type == "unique")
  const amazing = featureList.filter(f => f.type == "amazing")
  const { Option } = Select;

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      render: text => <img alt="features" src={`${imageUrl}${text}`} />
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'visible',
      key: 'visible',
      render: text => <Tag color={text ? "#2db7f5" : "#f50"}>{text ? "Show" : "Hide"}</Tag>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => {
        const action = record.visible ? "Hide" : "Show"
        return (
          <Space size="middle">
            <AntButton type="ghost" onClick={() => handleUpdateModal(record)}>Edit </AntButton>
            <AntButton type="danger" onClick={() => confirmAction(id, action)}>{action}</AntButton>
          </Space>
        )
      },
    },
  ];

  const confirmAction = (id, action) => {
    confirm({
      title: `Are you sure you want to ${action} this Contact?`,
      icon: <FontAwesomeIcon icon={faExclamation} color="primary" />,
      content: 'Click Ok to continue',
      onOk() {
        handleShowHide(id, action.toLowerCase())
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const handleShowHide = async (id, type) => {
    const date = new Date()
    const res = await updateFeature({ data: {}, type, id })
    if (res.status) {
      setResponse({ message: res.message, title: "success" })
      setShowAlert(true)
      setRefresh(date.toTimeString())
    } else {
      setResponse({ message: res.message || res, title: "failed" })
      setShowAlert(true)
    }
  }

  const onFinish = async (val) => {
    setLoading(true)
    const date = new Date()
    const fd = new FormData();
    fd.append('title', val.title)
    fd.append('description', val.description)
    fd.append('type', val.type)
    fd.append('feature_image', file);
    const res = await createFeature(fd)
    if (res.status) {
      setResponse({ message: res.message, title: "success" })
      setShowAlert(true)
      setRefresh(date.toTimeString())
      setViewModal(false)
    } else {
      setResponse({ message: res.message || res, title: "failed" })
      setShowAlert(true)
    }
    setLoading(false)
  }

  const onFinishUpdate = async (val) => {
    setLoading(true)
    const date = new Date()
    const fd = new FormData();
    fd.append('title', val.title)
    fd.append('description', val.description)
    fd.append('type', val.type)
    fd.append('id', val.id)
    fd.append('image_url', val.image_url)
    fd.append('feature_image', file);
    const res = await updateFeature({ data: fd, type: "update", id: val.id })
    if (res.status) {
      setResponse({ message: res.message, title: "success" })
      setShowAlert(true)
      setRefresh(date.toTimeString())
      setEditModal(false)
    } else {
      setResponse({ message: res.message || res, title: "failed" })
      setShowAlert(true)
    }
    setLoading(false)
  }

  const handleUpdateModal = (val) => {
    setEditModal(true)
    setValue(val)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = info => {
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setFile(info.file.originFileObj)
      });
    }
  };

  return (
    <div>
      <AlertToast showAlert={showAlert} setShowAlert={setShowAlert} response={response} page="Home" />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Waya pay</Breadcrumb.Item>
            <Breadcrumb.Item active>Home</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Home Page</h4>
        </div>
        <div className="table-settings mb-4">
          <Button variant="success" className="text-white me-3" onClick={() => setViewModal(true)}>
            Create New Feature <FontAwesomeIcon icon={faHome} className="d-none d-sm-inline ms-1" />
          </Button>
        </div>
      </div>
      <HomeCard featureImg={true} img={home.hero_image_url} description={home.hero_description} title={home.hero_title} type="hero" url="/admin/home_page/hero" />
      <Row>
        <h4>Explore features</h4>
        {explore.map(ex =>
          <Col xs={12} xl={4} lg={4} key={ex.id}>
            <HomeCard featureImg={true} img={ex.image_url} description={ex.description} title={ex.title} type="feature" id={ex.id} />
          </Col>)}
      </Row>
      <Row>
        <h4>Home features</h4>
        {feature.map(ex =>
          <Col xs={12} xl={4} lg={4} key={ex.id}>
            <HomeCard featureImg={false} img={ex.image_url} description={ex.description} title={ex.title} type="feature" id={ex.id} />
          </Col>)}
      </Row>

      <Row>
        <h4>Amazing features</h4>
        {amazing.map(ex =>
          <Col xs={12} xl={4} lg={4} key={ex.id}>
            <HomeCard featureImg={true} img={ex.image_url} description={ex.description} title={ex.title} type="feature" id={ex.id} />
          </Col>)}
      </Row>

      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4 text-primary">Unique Feature List</h5>
          <Table columns={columns} loading={loading} pagination={false} dataSource={unique} rowKey="id" />
        </Card.Body>
      </Card>
      <MediaCard img1={home.feature_image_url} img={home.download_app_image_url} video_url={home.feature_video_url} url="/admin/home_page/others" />

      {/*create feature start */}
      <Modal
        title="Create New Feature"
        centered
        visible={viewModal}
        footer={null}
        onCancel={() => setViewModal(false)}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item label="Title" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Type" name="type" rules={[{ required: true }]}>
            <Select onChange={(v) => { if (v == "home") { setUploadImage(false) } else { setUploadImage(true) } }}>
              <Option value="explore">Explore Feature</Option>
              <Option value="unique">Unique Feature</Option>
              <Option value="home">Home Feature</Option>
              <Option value="w-pos">Web Pos</Option>
              <Option value="t-pos">Terminal Pos</Option>
              <Option value="amazing">Amazing Feature</Option>
            </Select>
          </Form.Item>
          {uploadImage &&
            <Form.Item >
              <Upload onChange={handleChange} customRequest={dummyRequest} maxCount={1}>
                <AntButton icon={<InboxOutlined />}>Upload Feature Image</AntButton>
              </Upload>
            </Form.Item>}
          <Form.Item>
            <AntButton type="primary" htmlType="submit" loading={loading}>
              Create
            </AntButton>
          </Form.Item>
        </Form>
      </Modal>
      {/*create feature end */}

      {/* update feature start */}
      <Modal
        title="Update Feature"
        centered
        visible={editModal}
        footer={null}
        onCancel={() => setEditModal(false)}
      >
        <Form form={form} onFinish={onFinishUpdate} layout="vertical" initialValues={{ title: value.title, description: value.description, id: value.id, image_url: value.image_url, type: value.type }}>
          <Form.Item label="Title" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Type" name="type" rules={[{ required: true }]}>
            <Select onChange={(v) => { if (v == "home") { setUploadImage(false) } else { setUploadImage(true) } }}>
              <Option value="explore">Explore Feature</Option>
              <Option value="unique">Unique Feature</Option>
              <Option value="home">Home Feature</Option>
              <Option value="w-pos">Web Pos</Option>
              <Option value="t-pos">Terminal Pos</Option>
              <Option value="amazing">Amazing Feature</Option>
            </Select>
          </Form.Item>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          {uploadImage &&
            <Form.Item >
              <Upload onChange={handleChange} customRequest={dummyRequest} maxCount={1}>
                <AntButton icon={<InboxOutlined />}>Upload Feature Image</AntButton>
              </Upload>
            </Form.Item>}
          <Form.Item>
            <AntButton type="primary" htmlType="submit" loading={loading}>
              Update
            </AntButton>
          </Form.Item>
        </Form>
      </Modal>
      {/* update feature end */}
    </div>
  )
}

export default Home
